// import Vue from "vue";

const state = {
  selectedTicket: {},
  selectedVenue: '',
  selectedPlaceId: '',
  selectedEventId: '',
  selectedLanguage: 'es',
  isDarkTheme: true
}

const mutations = {
  SET_SELECTED_TICKET (state, selectedTicket) {
    state.selectedTicket = selectedTicket
  },
  SET_SELECTED_VENUE (state, selectedVenue) {
    state.selectedVenue = selectedVenue
  },
  SET_SELECTED_PLACE_ID (state, placeId) {
    state.selectedPlaceId = placeId
  },
  SET_SELECTED_EVENT_ID (state, eventId) {
    state.selectedEventId = eventId
  },
  SET_SELECTED_LANGUAGE (state, selectedLanguage) {
    state.selectedLanguage = selectedLanguage
  },
  SET_IS_DARK_THEME (state, isDarkTheme) {
    state.isDarkTheme = isDarkTheme
  }
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
