import eventsService from '@/services/eventsService.js'
import router from '@/router'
import browserID from 'browser-id'

const state = {
  eventsJsLoaded: false,
  loadingEvents: false,
  events: [],
  selectedEvent: {},
  hasBoughtTicket: false,
  canWatchLive: false
}

const mutations = {
  SET_EVENTS_JS_LOADED (state, payload) {
    state.eventsJsLoaded = payload
  },
  SET_LOADING_EVENTS (state, loading) {
    state.loadingEvents = loading
  },
  SET_EVENTS (state, events) {
    state.events = events
  },
  SET_SELECTED_EVENT (state, event) {
    state.selectedEvent = event
  },
  SET_HAS_BOUGHT_TICKET_FOR_EVENT (state, hasBoughtTicket) {
    state.hasBoughtTicket = hasBoughtTicket
  },
  SET_CAN_WATCH_LIVE_STREAM_EVENT (state, canWatchLive) {
    state.canWatchLive = canWatchLive
  }
}

const actions = {
  async getEvent ({ commit }, eventId) {
    const event = await eventsService.getEvent(eventId)
    if (event && event._id) {
      commit('SET_SELECTED_EVENT', event)
    } else {
      commit('SET_SELECTED_EVENT', {})
    }
  },

  async getEvents ({ commit, rootState }) {
    try {
      commit('SET_LOADING_EVENTS', true)
      if (rootState.filters.enabledFilters.events) {
        const response = await window.keybeEvents.getEvents(rootState.filters.formattedEventsFilter)
        if (Array.isArray(response)) {
          commit('SET_EVENTS', response)
        }
      } else {
        commit('SET_EVENTS', [])
      }
    } catch (error) {
      console.error(error)
      commit('SET_EVENTS', [])
    }
    commit('SET_LOADING_EVENTS', false)
  },
  async selectEvent ({ commit }, venueEvent) {
    commit('storedStates/SET_SELECTED_EVENT_ID', venueEvent._id, { root: true })
    router.push({
      name: 'VenueEventDetail',
      params: { id: venueEvent.slug }
    })
  },
  getPurchases () {
    return new Promise((resolve, reject) => {
      window.keybeEvents
        .getUserPurchases()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async setEventPurchase ({ state, rootState }, { ticketId, attendees, code }) {
    let ticketsEvents = []
    ticketsEvents.push({
      eventId: state.selectedEvent._id,
      ticketId,
      code
    })
    const eventData = {
      ticketsEvents,
      attendees: [],
      email: attendees[0].email,
      phone: attendees[0].phone
    }
    let attendeeData = {}
    for (let i = 0; i < attendees.length; i++) {
      attendeeData = {
        eventId: state.selectedEvent._id,
        ticketId,
        totalTickets: 1,
        ...attendees[i]
        // name: rootState.authKeybe.userInfo.name,
        // lastName: rootState.authKeybe.userInfo.lastName,
        // email: rootState.authKeybe.userInfo.email,
        // phone: {
        //   number: rootState.authKeybe.userInfo.phone
        // }
      }
      // attendeeData.document = (rootState.authKeybe.extraData.find(doc => doc.field === 'paymentUserDocNumber') || {}).value
      // console.log('rootState.authKeybe.extraData')
      // console.log(rootState.authKeybe.extraData)
      if (!attendeeData.name) {
        attendeeData.name = (rootState.authKeybe.extraData.find(doc => doc.field === 'paymentUserName') || {}).value
      }
      if (!attendeeData.email) {
        attendeeData.email = (rootState.authKeybe.extraData.find(doc => doc.field === 'paymentUserEmail') || {}).value
      }
      if (!attendeeData.phone.number) {
        attendeeData.phone.number = (rootState.authKeybe.extraData.find(doc => doc.field === 'paymentUserPhone') || {}).value
      }
      eventData.attendees.push(attendeeData)
    }

    const eventPurchaseResponse = await window.keybeEvents.setEventsPurchase(eventData)

    // const eventPurchaseResponse = await window.keybeEvents
    //   .setEventPurchase(state.selectedEvent._id, {
    //     ticketId,
    //     attendees,
    //     code
    //   })
    console.log('eventPurchaseResponse')
    console.log(eventPurchaseResponse)
    let chargeResponse
    if (rootState.money.purchaseConfirmation.paymentMethod === 'creditCard') {
      chargeResponse = await window.keybeEvents.chargeCard({
        purchaseId: eventPurchaseResponse.purchase._id,
        cardId: rootState.money.purchaseConfirmation.cardId,
        dues: rootState.money.purchaseConfirmation.dues,
        email: rootState.money.purchaseConfirmation.email,
        purchaseType: 'event'
      },
      undefined,
      true)
    } else if (rootState.money.purchaseConfirmation.paymentMethod === 'pse') {
      chargeResponse = await window.keybeEvents.createPSETransaction({
        ignoreProvider: true,
        purchaseId: eventPurchaseResponse.purchase._id,
        email: rootState.money.purchaseConfirmation.email,
        docType: rootState.money.purchaseConfirmation.docType,
        docNumber: rootState.money.purchaseConfirmation.docNumber,
        typePerson: rootState.money.purchaseConfirmation.typePerson,
        bank: rootState.money.purchaseConfirmation.bank,
        urlResponse: rootState.money.purchaseConfirmation.urlResponse,
        purchaseType: 'event',
        acceptanceToken: rootState.money.wompiAcceptanceToken
      })
    } else if (rootState.money.purchaseConfirmation.paymentMethod === 'cash') {
      console.log('not implemented yet')
    } else if (rootState.money.purchaseConfirmation.paymentMethod === 'mercadopago') {
      console.log('not implemented yet')
    }
    return chargeResponse
  },
  async getHasBoughtTicket ({ commit }, eventId) {
    const browserId = browserID()
    // console.log('browserId')
    // console.log(browserId)
    const eventAttendees = await window.keybeEvents.getEventAttendees({ eventId, browserId })
    // console.log('eventAttendees')
    // console.log(eventAttendees)
    let hasBoughtTicket = false
    let canWatchLive = false
    if (eventAttendees.attendees && Array.isArray(eventAttendees.attendees) && eventAttendees.attendees.length > 0) {
      hasBoughtTicket = true
    }
    if (eventAttendees.canWatchLive) canWatchLive = true
    commit('SET_HAS_BOUGHT_TICKET_FOR_EVENT', hasBoughtTicket)
    commit('SET_CAN_WATCH_LIVE_STREAM_EVENT', canWatchLive)
  }
}

const getters = {
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
