<template>
  <div class="headerbar-menu">
    <v-btn
      icon
      @click="drawer = !drawer"
    >
      <v-img
        src="https://storage.googleapis.com/keybe/kb.live/img/header-side_menu_toggle_btn.svg"
        max-width="22px"
        max-height="19px"
      ></v-img>
    </v-btn>

    <v-navigation-drawer
      app
      temporary
      v-model="drawer"
      right
      :class=" $vuetify.theme.dark ? 'black' : 'white'"
      height="100vh"
      hide-overlay
      dark
    >
      <v-list-item class="pr-0">
        <HeaderProfile @click="openProfileUI" />
        <v-spacer />
        <v-btn
          icon
          style="color:black;"
          @click="drawer = !drawer"
        >
          <v-img
            :src=" $vuetify.theme.dark ? 'https://storage.googleapis.com/keybe/kb.live/img/header-side_menu_toggle_btn.svg' : 'https://storage.googleapis.com/keybe/kb.live/img/bar-chart-2.png'"
            max-width="22px"
            max-height="19px"
          ></v-img>
        </v-btn>
      </v-list-item>

      <v-list dense nav class="pa-0 d-flex" style="flex-direction: column">
        <div>
          <v-list-item-group
            v-model="selectedItem"
            mandatory
            light
          >
            <v-list-item
              v-for="(item, index) in menuItems"
              :key="index"
              class="px-5 py-1"
              @click="openView(item.handler)"
            >
                <v-list-item-avatar tile width="20px" height="20px" min-width="20px" min-height="20px">
                  <v-img :src="index === selectedItem && $vuetify.theme.dark ? item.activeIconUrl : $vuetify.theme.dark ? item.iconUrl : item.iconUrlLight"></v-img>
                </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title :style="{ color: item.textColor }">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="px-5 py-1"
            >
              <v-list-item-content>
                <Language />
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="isLoggedIn"
              class="py-1"
            >
              <v-list-item-content>
                <!--- Logout Btn --->
                <v-btn block tile @click="logout">Logout</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </div>
        <!--- end menu logged --->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import HeaderProfile from './HeaderProfile'
import Language from '@/components/Header/Language'
export default {
  components: { HeaderProfile, Language },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.authKeybe.isLoggedIn
    }),

    menuItems () {
      return [
        // {
        //   title: 'People',
        //   icon: 'mdi-account-multiple-outline',
        //   textColor: '#fff',
        //   route: '#'
        // },
        // {
        //   title: 'Health',
        //   icon: 'mdi mdi-stethoscope',
        //   textColor: '#fff',
        //   route: '#'
        // },
        // {
        //   title: 'Money',
        //   icon: 'feather-dollar-sign',
        //   textColor: '#fff',
        //   route: '#'
        // },
        // {
        //   title: 'Likings',
        //   icon: 'mdi-heart-outline',
        //   textColor: '#fff',
        //   route: '#'
        // },
        // {
        //   title: 'LIVE',
        //   icon: 'mdi-star-outline',
        //   textColor: '#fff',
        //   route: '/'
        // },
        // {
        //   title: 'Access',
        //   icon: 'mdi-arrow-right',
        //   textColor: '#fff',
        //   route: '/purchases
        // },
        // {
        //   title: 'Preferences',
        //   icon: 'mdi-tune',
        //   textColor: '#fff',
        //   route: '#'
        // },
        // {
        //   title: 'Categories',
        //   activeIconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-categories_signs-active.svg',
        //   iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-categories_signs-active.svg',
        // //   iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-categories_signs-inactive.svg',
        //   textColor: '#fff',
        //   handler: 'openCategories'
        // },
        {
          title: this.$t('ticketsBookings'),
          activeIconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-tickets_n_bookings-active.svg',
          activeIconUrlLight: 'https://storage.googleapis.com/keybe/kb.live/img/kb.live_img_side_menu-tickets_n_bookings-active_grey.svg',
          iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-tickets_n_bookings-active.svg',
          iconUrlLight: 'https://storage.googleapis.com/keybe/kb.live/img/kb.live_img_side_menu-tickets_n_bookings-active_grey.svg',
          // iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-tickets_n_bookings-inactive.svg',
          textColor: this.$vuetify.theme.dark ? '#FFFFFF' : '#8898aa',
          handler: 'openTicketsAndBookings'
        },
        // Ocultado mientras que se conecta la maqueta con el back:
        // {
        //   title: this.$t('money'),
        //   activeIconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-money-active.svg',
        //   iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-money-active.svg',
        //   // iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-money-inactive.svg',
        //   textColor: '#fff',
        //   handler: 'openMoney'
        // },
        {
          title: this.$t('health'),
          activeIconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-health-active.svg',
          activeIconUrlLight: 'https://storage.googleapis.com/keybe/kb.live/img/kb.live_img_side_menu-health-active_grey.svg',
          iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-health-active.svg',
          iconUrlLight: 'https://storage.googleapis.com/keybe/kb.live/img/kb.live_img_side_menu-health-active_grey.svg',
          // iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-health-inactive.svg',
          textColor: this.$vuetify.theme.dark ? '#FFFFFF' : '#8898aa',
          handler: 'openHealth'
        },
        // {
        //   title: 'Contacts',
        //   activeIconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-contacts_users-active.svg',
        //   iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-contacts_users-active.svg',
        // //   iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-contacts_users-inactive.svg',
        //   textColor: '#fff',
        //   handler: 'openContacts'
        // },
        // {
        //   title: 'Favorites',
        //   activeIconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-favorites_heart-active.svg',
        //   iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-favorites_heart-active.svg',
        // //   iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-favorites_heart-inactive.svg',
        //   textColor: '#fff',
        //   handler: 'openFavorites'
        // },
        // {
        //   title: 'Inbox',
        //   activeIconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-inbox-conversations-active.svg',
        //   iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-inbox-conversations-active.svg',
        // //   iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-inbox-conversations-inactive.svg',
        //   textColor: '#fff',
        //   handler: 'openInbox'
        // },
        {
          title: this.$t('assistance'),
          activeIconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-assistance-active.svg',
          activeIconUrlLight: 'https://storage.googleapis.com/keybe/kb.live/img/kb.live_img_side_menu-assistance-active_grey.svg',
          iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-assistance-active.svg',
          iconUrlLight: 'https://storage.googleapis.com/keybe/kb.live/img/kb.live_img_side_menu-assistance-active_grey.svg',
          // iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-assistance-inactive.svg',
          textColor: this.$vuetify.theme.dark ? '#FFFFFF' : '#8898aa',
          handler: 'openAssistance'
        },
        {
          title: this.$t('aboutKblive'),
          activeIconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-about_information-active.svg',
          activeIconUrlLight: 'https://storage.googleapis.com/keybe/kb.live/img/kb.live_img_side_menu-about_information-active_grey.svg',
          iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-about_information-active.svg',
          iconUrlLight: 'https://storage.googleapis.com/keybe/kb.live/img/kb.live_img_side_menu-about_information-active_grey.svg',
          // iconUrl: 'https://storage.googleapis.com/keybe/kb.live/img/side_menu-about_information-inactive.svg',
          textColor: this.$vuetify.theme.dark ? '#FFFFFF' : '#8898aa',
          handler: 'openAbout'
        }
      ]
    }
  },
  data () {
    return {
      selectedItem: 0,
      drawer: false
    }
  },
  methods: {
    closeDrawer () {
      this.drawer = !this.drawer
    },
    openProfileUI () {
      window.location.hash = '/kb-profile'
      // window.keybe.uiOpenModal()
      if (this.drawer) {
        this.closeDrawer()
      }
    },
    async openView (view) {
      await this[view]()
    },
    openCategories () {
      console.log('open cat')
    },
    openTicketsAndBookings () {
      this.$router.push({ name: 'Purchases' })
      // window.dispatchEvent(new CustomEvent('hashchange'))
    },
    openMoney () {
      window.location.hash = '/kb-profile/money'
      if (this.drawer) {
        this.closeDrawer()
      }
    },
    openHealth () {
      window.location.hash = '/kb-profile/health'
      if (this.drawer) {
        this.closeDrawer()
      }
    },
    openContacts () {},
    openFavorites () {},
    openInbox () {},
    openAssistance () {
      this.closeDrawer()
      window.keybe.openChat()
    },
    openAbout () {
      window.open('https://keybe.ai/', '_blank')
    },
    async logout () {
      await window.keybe.logout()
      this.closeDrawer()
    }
  },
  watch: {
    // selectedItem: {
    //   handler (newValue, oldValue) {
    //     if (newValue === undefined) {
    //       this.selectedItem = oldValue
    //     }
    //   },
    //   deep: true
    // }
  }
}
</script>

<style lang="scss" scoped>
.headerbar-menu {
  padding-left: 5px;
  .v-navigation-drawer {
    overflow: auto;
    .v-item-group {
      /** Bug al esconder nav-drawer se quedaba mostrando los items */
      transition: none;
      .v-list-item--link:before {
        /** Bug al esconder nav-drawer se quedaba mostrando por 3 seg el item que tiene activo */
        transition: 0ms !important;
      }
    }
  }
  /** Bug estaba mostrando los iconos demasiado grandes y cortados */
  ::v-deep .v-image__image--cover {
    background-size: contain !important;
  }
}

</style>
