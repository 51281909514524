import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import authKeybe from '@/store/modules/authKeybe'
import events from '@/store/modules/events'
import places from '@/store/modules/places'
import filters from '@/store/modules/filters'
import tempStates from '@/store/modules/tempStates'
import storedStates from '@/store/modules/storedStates'
import money from '@/store/modules/money'
import shoppingCart from '@/store/modules/shoppingCart'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { authKeybe, events, places, filters, money, tempStates, storedStates, shoppingCart },
  plugins: [
    createPersistedState({ key: 'kb-live', paths: ['storedStates', 'shoppingCart'] })
  ]
})
