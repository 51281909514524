<template>
  <v-row justify="center">
    <v-col cols="12" align="center">
      <a
      class="footer-icons"
      href="https://www.instagram.com/keybe.ai/"
      target="_blank">
        <v-icon :style="{color: $vuetify.theme.dark ? '#ffffff' : '#8898aa'}">mdi-instagram</v-icon>
      </a>

      <a
        class="footer-icons"
        href="https://www.youtube.com/watch?v=NuuAcDhDLec&t=4s"
        target="_blank"
      >
        <v-icon :style="{color: $vuetify.theme.dark ? '#ffffff' : '#8898aa'}">mdi-youtube</v-icon>
      </a>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'FooterIcons'
}
</script>
<style scoped>
.footer-icons {
  padding: 5px;
  text-decoration: none;
}
</style>
