export default {
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.xs
    },
    isTablet () {
      return this.$vuetify.breakpoint.sm
    },
    isLaptop () {
      return this.$vuetify.breakpoint.md
    },
    isDesktop () {
      return this.$vuetify.breakpoint.lg
    }
  }
}
