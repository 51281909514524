// import Vue from "vue";

const state = {
  // Exactly as it comes (Please don't modify it after it is set on the request):
  placesCategories: [],
  eventsCategories: [],
  indexOfRestaurantPlaceCategoryId: '',
  // Enable filters
  enabledFilters: {
    events: true,
    places: true,
    restaurants: true
  },
  // Filters as we work with them
  placesCategoriesFilters: {},
  eventsCategoriesFilters: {},
  // Filters as we send it to back:
  formattedPlacesFilter: {
    filters: {
      and: [],
      or: [
      // {
      //   and: []
      // }
      ]
    }
  },
  formattedEventsFilter: {
    filters: {
      and: [],
      or: [
        // {
        //   and: []
        // }
      ]
    }
  }
}

const mutations = {
  SET_ENABLED_FILTERS (state, enabledFilters) {
    state.enabledFilters = enabledFilters
  },
  SET_PLACES_CATEGORIES_FILTERS (state, placesCategoriesFilters) {
    state.placesCategoriesFilters = placesCategoriesFilters
  },
  SET_EVENTS_CATEGORIES_FILTERS (state, eventsCategoriesFilters) {
    state.eventsCategoriesFilters = eventsCategoriesFilters
  },
  SET_FORMATTED_PLACES_FILTER (state, formattedPlacesFilter) {
    state.formattedPlacesFilter = formattedPlacesFilter
  },
  SET_FORMATTED_EVENTS_FILTER (state, formattedEventsFilter) {
    state.formattedEventsFilter = formattedEventsFilter
  },
  SET_PLACES_CATEGORIES (state, categories) {
    state.placesCategories = categories
  },
  SET_EVENTS_CATEGORIES (state, categories) {
    state.eventsCategories = categories
  },
  SET_RESTAURANTS_CATEGORY_ID (state, categoryId) {
    state.indexOfRestaurantPlaceCategoryId = categoryId
  }
}

const actions = {
  async getEventsCategories ({ commit, state }) {
    const eventsCategories = await window.keybeEvents.getEventsCategories()
    if (eventsCategories && eventsCategories.rows && eventsCategories.rows.length) {
      commit('SET_EVENTS_CATEGORIES', eventsCategories.rows)
      const eventsCategoriesFilters = {}
      try {
        for (let i = 0; i < eventsCategories.rows.length; i++) {
          const preferences = {}
          let categoryKeyAlreadyExist = false
          let activeCategory = true
          // Check if already exist
          if (state.eventsCategoriesFilters[eventsCategories.rows[i].id]) {
            categoryKeyAlreadyExist = true
            activeCategory = state.eventsCategoriesFilters[eventsCategories.rows[i].id].active
          }
          for (let j = 0; j < eventsCategories.rows[i].preferences.length; j++) {
            let preferenceKeyAlreadyExist = false
            if (categoryKeyAlreadyExist &&
              state.eventsCategoriesFilters[eventsCategories.rows[i].id].preferences[eventsCategories.rows[i].preferences[j]._id]) {
              preferenceKeyAlreadyExist = true
            }
            let activePreference = true
            if (categoryKeyAlreadyExist && preferenceKeyAlreadyExist) {
              activePreference = state.eventsCategoriesFilters[eventsCategories.rows[i].id].preferences[eventsCategories.rows[i].preferences[j]._id].active
            }
            preferences[eventsCategories.rows[i].preferences[j]._id] = {
              active: activePreference,
              preferenceId: eventsCategories.rows[i].preferences[j]._id
            }
          }
          eventsCategoriesFilters[eventsCategories.rows[i].id] = {
            active: activeCategory,
            eventCategoryId: eventsCategories.rows[i].id,
            preferences
          }
        }
      } catch (error) {
        console.error(error)
      }
      commit('SET_EVENTS_CATEGORIES_FILTERS', eventsCategoriesFilters)
    }
  },
  async getPlacesCategories ({ commit, state }) {
    const placesCategories = await window.keybeEvents.getPlacesCategories()
    if (placesCategories && placesCategories.rows && placesCategories.rows.length) {
      try {
        let indexOfRestaurantPlaceCategoryId
        for (let i = 0; i < placesCategories.rows.length; i++) {
          if (placesCategories.rows[i].name === 'Restaurant') {
            indexOfRestaurantPlaceCategoryId = placesCategories.rows[i].id
            placesCategories.rows.splice(i, 1)
            placesCategories.count -= 1
            break
          }
        }
        // Set the id of restaurants:
        commit('SET_RESTAURANTS_CATEGORY_ID', indexOfRestaurantPlaceCategoryId)
      } catch (error) {
        commit('SET_RESTAURANTS_CATEGORY_ID', '')
        console.error(error)
      }
      commit('SET_PLACES_CATEGORIES', placesCategories.rows)
      const placesCategoriesFilters = {}
      try {
        for (let i = 0; i < placesCategories.rows.length; i++) {
          const preferences = {}
          let categoryKeyAlreadyExist = false
          let activeCategory = true
          // Check if already exist
          if (state.placesCategoriesFilters[placesCategories.rows[i].id]) {
            categoryKeyAlreadyExist = true
            activeCategory = state.placesCategoriesFilters[placesCategories.rows[i].id].active
          }
          for (let j = 0; j < placesCategories.rows[i].preferences.length; j++) {
            let preferenceKeyAlreadyExist = false
            if (categoryKeyAlreadyExist &&
              state.placesCategoriesFilters[placesCategories.rows[i].id].preferences[placesCategories.rows[i].preferences[j]._id]) {
              preferenceKeyAlreadyExist = true
            }
            let activePreference = true
            if (categoryKeyAlreadyExist && preferenceKeyAlreadyExist) {
              activePreference = state.placesCategoriesFilters[placesCategories.rows[i].id].preferences[placesCategories.rows[i].preferences[j]._id].active
            }
            preferences[placesCategories.rows[i].preferences[j]._id] = {
              active: activePreference,
              preferenceId: placesCategories.rows[i].preferences[j]._id
            }
          }
          placesCategoriesFilters[placesCategories.rows[i].id] = {
            active: activeCategory,
            placeCategoryId: placesCategories.rows[i].id,
            preferences
          }
        }
      } catch (error) {
        console.error(error)
      }
      commit('SET_PLACES_CATEGORIES_FILTERS', placesCategoriesFilters)
    }
  }
}

const getters = {
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
