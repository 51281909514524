import { render, staticRenderFns } from "./HeaderSidebarMenu.vue?vue&type=template&id=d247ec34&scoped=true&"
import script from "./HeaderSidebarMenu.vue?vue&type=script&lang=js&"
export * from "./HeaderSidebarMenu.vue?vue&type=script&lang=js&"
import style0 from "./HeaderSidebarMenu.vue?vue&type=style&index=0&id=d247ec34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.3_b61fd53126d8a76b25cc5244d186282f/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d247ec34",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.6.0_9053c641aa08e2db0287c4c8a8df6e99/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VImg,VList,VListItem,VListItemAvatar,VListItemContent,VListItemGroup,VListItemTitle,VNavigationDrawer,VSpacer})
