<template>
  <v-app>
    <MainLayout
      @show-prev-venue="showPrevVenue"
      @show-next-venue="showNextVenue"
      @select-venue="selectVenue"
      :venuesData="venuesData"
    />
    <vue-snotify />
  </v-app>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import { mapState } from 'vuex'

export default {
  name: 'App',

  components: {
    MainLayout
  },
  computed: {
    ...mapState({
      eventsJsLoaded: (state) => state.events.eventsJsLoaded,
      events: (state) => state.events.events,
      places: (state) => state.places.places,
      userExtraData: state => state.authKeybe.extraData,
      language: state => state.storedStates.selectedLanguage
    }),
    selectedVenueIndex: {
      get () {
        return this.$store.state.tempStates.selectedVenueIndex
      },
      set (value) {
        this.$store.commit('tempStates/SET_SELECTED_VENUE_INDEX', value)
      }
    },
    venuesData () {
      const places = this.places.map((place) => ({
        ...place,
        component: 'Place'
      }))
      const events = this.events.map((event) => ({
        ...event,
        component: 'Event'
      }))
      const venues = [...places, ...events]
      return venues
    }
  },

  data: () => ({}),
  created () {
    // eslint-disable-next-line no-console
    console.log('🏆 Kb.Live 🌝 Released Jun 6 17:10')
    this.$vuetify.theme.dark = false
    let obj = document.getElementById('keybe-host')
    while (obj && obj.nodeName !== 'BODY') {
      let position = window
        .getComputedStyle(obj, null)
        .getPropertyValue('position')
      if (position === 'relative') { obj.style.setProperty('position', 'static', 'important') }
      obj = obj.parentNode
    }
  },
  mounted () {
    // let hostname = 'live.kbe.ai'
    // Microsites:
    let hostname = location.hostname || 'keybe.kbe.ai'
    // if (hostname === 'localhost') hostname = 'live.kbe.ai' // 'keybe.kbe.ai'
    if (hostname === 'localhost') hostname = 'keybe.kbe.ai'
    if (hostname === 'kb.live') hostname = 'live.kbe.ai'
    if (hostname === 'test.kb.live') hostname = 'live.kbe.ai'
    // eslint-disable-next-line no-console
    console.log('hostname:')
    // eslint-disable-next-line no-console
    console.log(hostname)
    const parts = hostname.split('.')
    const appSlug = parts.shift()
    // eslint-disable-next-line no-console
    console.log('appSlug:')
    // eslint-disable-next-line no-console
    console.log(appSlug)
    const appId = appSlug || '0b811ecb726640e6826daadd816663db' // kb.live

    // call the event keybeLiveFrontLoaded to init kb-js
    let customEvent = new CustomEvent('keybeLiveFrontLoaded', {
      detail: {
        language: this.language,
        apiKey: appId
      }
    })
    window.dispatchEvent(customEvent)
    window.addEventListener('onLoginSuccess', () => {
      window.keybe.uiCloseModal()
      this.$store.dispatch('authKeybe/isLoggedIn')
    })

    window.addEventListener('onLogout', () => {
      window.keybe.uiCloseModal()
      this.$store.dispatch('authKeybe/isLoggedIn')
      this.$store.commit('authKeybe/CLEAR_USER_INFO')
    })

    window.addEventListener('onRegisterSuccess', () => {
      window.keybe.uiCloseModal()
      this.$store.dispatch('authKeybe/isLoggedIn')
    })

    window.addEventListener('eventsJsLoaded', () => {
      this.$store.commit('events/SET_EVENTS_JS_LOADED', true)
      this.$store.dispatch('authKeybe/getAppInfo')
      this.$store.dispatch('authKeybe/isLoggedIn')
      // TODO: Make the request only in venueslist, not in the whole app
      // get data you always need
      this.$store.dispatch('events/getEvents')
      this.$store.dispatch('places/getPlaces')
    })
    window.addEventListener('webchatConversationsUiLoaded', () => {
      if (hostname !== 'live.kbe.ai') {
        // eslint-disable-next-line no-console
        console.log('Openning chat')
        window.keybe.openChat()
      }
    })
  },
  methods: {
    showNextVenue () {
      const newSelectedVenueIndex = (this.selectedVenueIndex + 1) % this.venuesData.length
      this.selectVenue({
        venueIndex: newSelectedVenueIndex
      })
    },
    showPrevVenue () {
      const newSelectedVenueIndex = (this.selectedVenueIndex + this.venuesData.length - 1) % this.venuesData.length
      this.selectVenue({
        venueIndex: newSelectedVenueIndex
      })
    },
    selectVenue ({ venueItem, venueIndex }) {
      // console.log('venueItem')
      // console.log(venueItem)
      // console.log('venueIndex')
      // console.log(venueIndex)
      if (venueItem === undefined && venueIndex === undefined) throw new Error('undefined venueItem or venueIndex')
      if (venueItem === undefined) {
        venueItem = this.venuesData[venueIndex]
      }
      if (venueItem.component === 'Place') { this.$store.dispatch('places/selectPlace', venueItem) }
      if (venueItem.component === 'Event') { this.$store.dispatch('events/selectEvent', venueItem) }
      if (venueIndex !== undefined) {
        this.selectedVenueIndex = venueIndex
      }
      // Solucion momentanea para evitar que me muestre el carrito de compras de otro place
      this.$store.commit('shoppingCart/CLEAR_CART_ITEMS')
    }
  },
  watch: {
    userExtraData: {
      deep: true,
      handler (value) {
        // console.log('value')
        // console.log(value)
        // console.log(JSON.parse(this.userExtraData.placesCategoriesFilters))
        if (this.userExtraData && this.userExtraData.enabledFilters) {
          this.$store.commit('filters/SET_ENABLED_FILTERS', JSON.parse(this.userExtraData.enabledFilters))
        }
        if (this.userExtraData && this.userExtraData.placesCategoriesFilters) {
          this.$store.commit('filters/SET_PLACES_CATEGORIES_FILTERS', JSON.parse(this.userExtraData.placesCategoriesFilters))
        }
        if (this.userExtraData && this.userExtraData.eventsCategoriesFilters) {
          this.$store.commit('filters/SET_EVENTS_CATEGORIES_FILTERS', JSON.parse(this.userExtraData.eventsCategoriesFilters))
        }
        if (this.userExtraData && this.userExtraData.formattedPlacesFilter) {
          this.$store.commit('filters/SET_FORMATTED_PLACES_FILTER', JSON.parse(this.userExtraData.formattedPlacesFilter))
        }
        if (this.userExtraData && this.userExtraData.formattedEventsFilter) {
          this.$store.commit('filters/SET_FORMATTED_EVENTS_FILTER', JSON.parse(this.userExtraData.formattedEventsFilter))
        }
        this.$store.dispatch('events/getEvents')
        this.$store.dispatch('places/getPlaces')
      }
    }
  }
}
</script>
