// import Vue from "vue";

const state = {
  selectedVenueIndex: undefined,
  floatingActionButtons: []
}

const mutations = {
  SET_SELECTED_VENUE_INDEX (state, selectedVenueIndex) {
    state.selectedVenueIndex = selectedVenueIndex
  },
  SET_FLOATING_ACTION_BUTTONS (state, floatingActionButtons) {
    state.floatingActionButtons = floatingActionButtons
  }
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
