const eventsService = {}

eventsService.getAttendeePDF = (getAttendeePDF) => {
  window.keybeEvents.getAttendeePdf(getAttendeePDF)
}

eventsService.getPurchase = (purchaseId) => {
  return new Promise((resolve, reject) => {
    window.keybeEvents
      .getUserPurchase(purchaseId)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

eventsService.getUserPurchases = () => {
  return window.keybeEvents.getUserPurchases()
}

eventsService.getEvent = (eventId) => {
  return new Promise((resolve, reject) => {
    return window.keybeEvents
      .getEvent(eventId)
      .then((event) => {
        resolve(event)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default eventsService
