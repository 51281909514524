import Image from '@/assets/img/keybe-live-1440x759.jpg'
export default {
  methods: {
    backgroundImage (imageProps) {
      const backgroundImage = {
        width: imageProps.width || 'inherit',
        height: imageProps.height || 'inherit',
        'background-size': (imageProps.backgroundSize === undefined && 'cover') || imageProps.backgroundSize,
        'background-position': imageProps.backgroundPosition || 'center center',
        'background-repeat': imageProps.backgroundRepeat || 'no-repeat',
        'border-radius': imageProps.borderRadius || '0px 0px 0px 0px',
        'background-color': (imageProps.backgroundColor === undefined && 'black') || imageProps.backgroundColor,
        'position': (imageProps.position === undefined && 'aboslute') || imageProps.position,
        ...imageProps.extra
      }
      if (imageProps.url !== null) backgroundImage['background-image'] = `url(${imageProps.url || Image})`
      return backgroundImage
    }
  }
}
