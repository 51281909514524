import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      aboutKblive: 'About KB: LIVE',
      accept: 'Accept',
      accessButton: 'Access',
      accessCategories: 'Access',
      accessEvent: 'Access event',
      accessValidFor: 'Access valid for',
      add: 'Add',
      addAttendee: 'Add another attendee',
      addCreditCard: 'Add Credit Card',
      addMessage: 'Add message',
      address: 'Address',
      americanExpress: 'American express',
      assistance: 'Assistance',
      attendee: 'No attendee | Attendee | {n} Attendees',
      attendees: 'Attendees',
      balance: 'Balance',
      bank: 'Bank',
      brunch: 'Brunch',
      buffet: 'Buffet',
      businessCasual: 'Business casual',
      cancel: 'Cancel',
      cardExpirationDate: 'Expiration Date',
      cardholderIdentificationNumber: 'Cardholder identification number',
      cardholderIdentificationType: 'Cardholder identification type',
      cardholderName: 'Cardholder name',
      cardholderLastName: 'Cardholder last name',
      cardNumber: 'Card Number',
      cash: 'Cash',
      casual: 'Casual',
      casualElegant: 'Casual elegant',
      casualDining: 'Casual dining',
      changeToPrincipal: 'Change To Principal',
      close: 'Close',
      closed: 'Closed',
      completesThePersonalInformationOfTheAttendees: 'Completes the personal information of the attendees',
      continue: 'Continue',
      confirmPurchase: 'Confirm Purchase',
      creditCard: 'Credit Card',
      creditCardSuccessfullyAdded: 'Credit card successfully added',
      dateAndTime: 'Date and time',
      daily: 'Daily',
      days: 'Days',
      diningStyle: 'Dining Style',
      dinner: 'Dinner',
      discover: 'Discover',
      docType: 'Document type',
      docNumber: 'Document Number',
      download: 'Download',
      downloadTicket: 'Download access',
      downloadYourTickets: 'Download your tickets',
      edit: 'Edit',
      ends: 'Ends',
      enterAPhoneNumber: 'Enter a phone number',
      entrances: 'Entrances',
      errorOnPurchase: 'An unknown error has happened in the purchasing process',
      event: 'Event',
      events: 'Events',
      email: 'Email',
      fastCasual: 'Fast Casual',
      fastFood: 'Fast Food',
      fineDining: 'Fine dining',
      formal: 'Formal',
      freeAccess: 'Free Access',
      freeParking: 'Free parking',
      friday: 'Friday',
      fullName: 'Full name',
      getAccess: 'Get Access',
      goToMyPurchases: 'Go to my purchases',
      goToPayment: 'Go to pay',
      gourmet: 'Gourmet',
      great: 'Great!',
      happyHour: 'Happy Hour',
      health: 'Health',
      hours: 'Hours',
      iAgreeWithThe: 'I agree to the',
      identification: 'Identification',
      information: 'Information',
      informationNeeded: 'Information needed',
      invalidEmailAddress: 'Invalid email address',
      invalidPhone: 'Invalid phone',
      invalidUrl: 'Invalid url',
      jacketAndTie: 'Jacket and tie',
      jacketRequired: 'Jacket required',
      lastDigits: 'Last Digits',
      liveEventTitle: 'LIVE',
      liveEventPeopleAmount: 'People',
      login: 'Login',
      loginAccessCode: 'Access code',
      loadingPurchases: 'Loading purchases',
      lunch: 'Lunch',
      mainCard: 'Tarjeta principal',
      mastercard: 'Mastercard',
      message: 'Message',
      minutes: 'Minutes',
      money: 'Money',
      monday: 'Monday',
      mustInsertAccessCode: 'You must insert the access code',
      ok: 'Ok',
      onlyNumbers: 'Only numbers are allowed',
      open: 'Open',
      overview: 'Overview',
      passwordMin8Length: 'Password must have at least 8 characters',
      passwordMinLength: 'The password does not meet the minimum number of characters',
      pay: 'Pay',
      paymentMethod: 'Payment method',
      personType: 'Person Type',
      phone: 'Phone',
      places: 'Places',
      pleaseLoginBeforeConfirmPayment: 'Please, login before confirm your purchase',
      pleaseLoginBeforeViewingYourPurchases: 'Please, login before viewing your purchases',
      pleaseLoginIfYouBoughtATicket: 'Please login if you bought a ticket',
      price: 'Price',
      privacyAgreement: 'PRIVACY AGREEMENT',
      products: 'Product | Products',
      profile: 'Profile',
      publicLot: 'Public lot',
      purchaseAtendees: 'Purchase attendees',
      purchases: 'Purchases',
      purchaseSummary: 'Purchase Summary',
      qrPayment: 'Qr payment',
      quantity: 'Number of persons',
      restaurants: 'Restaurants',
      save: 'Save',
      saturday: 'Saturday',
      seconds: 'Seconds',
      securityCode: 'Security code',
      selectCreditCardForPayment: 'Select Credit Card For Payment',
      selectPaymentMethod: 'Select Payment Method',
      selectYourTicket: 'Type of access',
      selfService: 'Self service',
      setPaymentData: 'Set Payment Data',
      share: 'Share',
      soldOut: 'Sold Out',
      starts: 'Starts',
      streetParking: 'Street parking',
      sunday: 'Sunday',
      termsAndConditions: 'terms and conditions',
      thereAreNoAttendeesForThisPurchase: 'There are no attendees for this purchase',
      thursday: 'Thursday',
      ticketAccesCode: 'ACCESS CODE',
      ticketsBookings: 'Tickets & Bookings',
      ticketPrice: 'Ticket Price',
      ticketsPurchased: 'Tickets purchased',
      ticketService: 'Ticket Service',
      ticketType: 'Ticket type',
      toPay: 'To pay',
      total: 'Total',
      totalPaid: 'Total paid',
      totalPayAmount: 'Total pay amount',
      transactionDate: 'Transaction date',
      transactionStatus: 'Transaction status',
      tuesday: 'Tuesday',
      valetParking: 'Valet parking',
      validPhone: 'Valid phone number',
      viewAttendees: 'View attendees',
      visa: 'Visa',
      yourAccount: 'My account',
      yourAccessHasBeenApproved: 'Your access has been approved',
      yourPaymentHasBeenApproved: 'Your payment has been approved',
      youDontHaveEntracesYet: `You don't have entraces yet`,
      youHaveNotAddedCreditCardsYet: `You haven't added credit cards yet`,
      youHaveNotAddedProductsYet: `You haven't added products yet`,
      youMustAcceptThePrivacyAgreement: 'You must accept the Privacy Agreement',
      website: 'Website',
      wednesday: 'Wednesday',
      whichCardWillBeUsed: 'Which card will be used?',
      whichPaymentMethodWillBeUsed: 'Which payment method will be used?',
      whoIsComing: `Who's coming?`
    },
    es: {
      aboutKblive: 'Más Info de KB: LIVE',
      accept: 'Aceptar',
      accessButton: 'Ingresar',
      accessCategories: 'Tipos de entradas',
      accessEvent: 'Entrar al evento',
      accessValidFor: 'Acceso válido para',
      add: 'Agregar',
      addAttendee: 'Agregar un asistente',
      addCreditCard: 'Agregar tarjeta de crédito',
      addMessage: 'Agregar mensaje',
      address: 'Dirección',
      americanExpress: 'American express',
      assistance: 'Asistencia',
      attendee: 'No hay asistentes | Asistente | {n} Asistentes',
      attendees: 'Asistentes',
      balance: 'Saldo',
      bank: 'Banco',
      brunch: 'Brunch',
      buffet: 'Bufet',
      businessCasual: 'Negocios casual',
      cancel: 'Cancelar',
      cardExpirationDate: 'Fecha de vencimiento',
      cardholderIdentificationNumber: 'Número de itentificación del propietario',
      cardholderIdentificationType: 'Tipo de itentificación del propietario',
      cardholderName: 'Nombre del propietario',
      cardholderLastName: 'Apellido del propietario',
      cardNumber: 'Número de la tarjeta',
      cash: 'Efectivo',
      casual: 'Casual',
      casualElegant: 'Elegante casual',
      casualDining: 'Cocina casual',
      changeToPrincipal: 'Cambiar a principal',
      close: 'Cerrar',
      closed: 'Cerrado',
      completesThePersonalInformationOfTheAttendees: 'Completa la información personal de los asistentes',
      continue: 'Continuar',
      confirmPurchase: 'Confirma tus entradas',
      creditCard: 'Tarjeta de Crédito',
      creditCardSuccessfullyAdded: 'La tarjeta de crédito fue creada exitosamente',
      dateAndTime: 'Fecha y hora',
      daily: 'Todos los días',
      days: 'Días',
      diningStyle: 'Estilo de cocina',
      dinner: 'Cena',
      discover: 'Discover',
      docType: 'Tipo de documento',
      docNumber: 'Número de documento',
      download: 'Descargar',
      downloadTicket: 'Descargar acceso',
      downloadYourTickets: 'Descarga tus boletas',
      edit: 'Editar',
      ends: 'Finaliza',
      enterAPhoneNumber: 'Celular',
      entrances: 'Entradas',
      errorOnPurchase: 'Hubo un error al ejecutar la compra',
      event: 'Event',
      events: 'Eventos',
      email: 'Correo electrónico',
      fastCasual: 'Cocina Lijera',
      fastFood: 'Comida Rapida',
      fineDining: 'Alta cocina',
      formal: 'Formal',
      freeAccess: 'Libre',
      freeParking: 'Parqueadero libre',
      friday: 'Viernes',
      fullName: 'Nombre completo',
      getAccess: 'Confirma tus entradas',
      goToMyPurchases: 'Ir a mis compras',
      goToPayment: 'Ir a pagar',
      gourmet: 'Gourmet',
      great: '¡Genial!',
      happyHour: 'Happy Hour',
      health: 'Salud',
      hours: 'Horas',
      iAgreeWithThe: 'Estoy de acuerdo con el',
      identification: 'Identificación',
      information: 'Información',
      informationNeeded: 'Este campo es obligatorio',
      invalidEmailAddress: 'Dirección de correo inválida',
      invalidPhone: 'Teléfono inválido',
      invalidUrl: 'Url inválida',
      jacketAndTie: 'Chaqueta y corbata',
      jacketRequired: 'Chaqueta requerida',
      lastDigits: 'Últimos dígitos',
      liveEventTitle: 'Estamos en vivo',
      liveEventPeopleAmount: 'Personas',
      login: 'Entrar',
      loginAccessCode: 'Ingresa el código de acceso que enviamos a tu celular y correo electrónico',
      loadingPurchases: 'Cargando compras',
      lunch: 'Almuerzo',
      mainCard: 'Main Card',
      mastercard: 'Mastercard',
      message: 'Mensaje',
      minutes: 'Minutos',
      money: 'Dinero',
      monday: 'Lunes',
      mustInsertAccessCode: 'Debes ingresar el código de acceso',
      ok: 'Entendido',
      onlyNumbers: 'Solo se permiten números',
      open: 'Abierto',
      overview: 'Descripción',
      passwordMin8Length: 'La contraseña debe tener mínimo 8 carácteres',
      passwordMinLength: 'La contraseña no cumple con el mínimo de carácteres',
      pay: 'Pagar',
      paymentMethod: 'Método de pago',
      personType: 'Tipo de persona',
      phone: 'Teléfono',
      places: 'Lugares',
      pleaseLoginBeforeConfirmPayment: 'Por favor, inicia sesión antes de confirmar la compra',
      pleaseLoginBeforeViewingYourPurchases: 'Por favor, identifícate antes de ver tus compras',
      pleaseLoginIfYouBoughtATicket: 'Por favor inicia sesión si compraste una boleta',
      price: 'Precio',
      privacyAgreement: 'ACUERDO DE PRIVACIDAD',
      products: 'Producto | Productos',
      profile: 'Perfil',
      publicLot: 'Parqueadero público',
      purchaseAtendees: 'Asistentes',
      purchases: 'Compras',
      purchaseSummary: 'Resumen',
      return: 'Regresar',
      qrPayment: 'Pago con qr',
      quantity: 'Cantidad de personas',
      restaurants: 'Restaurantes',
      save: 'Guardar',
      saturday: 'Sábado',
      seconds: 'Segundos',
      securityCode: 'Código de seguridad',
      selectCreditCardForPayment: 'Selecciona la tarjeta a usar para el pago',
      selectPaymentMethod: 'Selecciona el método de pago',
      selectYourTicket: 'Tipo de acceso',
      selfService: 'Autoserivio',
      setPaymentData: 'Datos para el pago',
      share: 'Compartir',
      soldOut: 'Agotado',
      starts: 'Empieza',
      streetParking: 'Parqueadero en la calle',
      sunday: 'Domingo',
      termsAndConditions: 'términos y condiciones',
      thereAreNoAttendeesForThisPurchase: 'No hay asistentes para esta compra',
      thursday: 'Jueves',
      ticketAccesCode: 'INGRESA AQUÍ TU CÓDIGO DE ACCESO',
      ticketsBookings: 'Tickets & Reservas',
      ticketPrice: 'Precio por entrada',
      ticketsPurchased: 'Boletas adquiridas',
      ticketService: 'Servicio de ticket',
      ticketType: 'Tipo de boleta',
      toPay: 'A pagar',
      total: 'Total',
      totalPaid: 'Total pagado',
      totalPayAmount: 'Total a pagar',
      transactionDate: 'Fecha de transacción',
      transactionStatus: 'Estado de la transacción',
      tuesday: 'Martes',
      valetParking: 'Parqueadero con valet',
      validPhone: 'Teléfono válido',
      viewAttendees: 'Ver asistentes',
      visa: 'Visa',
      yourAccount: 'Tu cuenta',
      yourAccessHasBeenApproved: 'Tu acceso ha sido aprobado',
      yourPaymentHasBeenApproved: 'Tu pago ha sido aprobado',
      youDontHaveEntracesYet: 'Aún no tienes boletas para este evento',
      youHaveNotAddedCreditCardsYet: 'Aún no tienes tarjetas de crédito asociadas',
      youHaveNotAddedProductsYet: 'Aún no tienes productos agregados al carrito',
      youMustAcceptThePrivacyAgreement: 'Por favor acepta el acuerdo de privacidad',
      website: 'Sitio web',
      wednesday: 'Miércoles',
      whichCardWillBeUsed: '¿Cuál tarjeta vas a usar?',
      whichPaymentMethodWillBeUsed: 'Selecciona el método de pago',
      whoIsComing: `Asistentes`
    }
  }
})
