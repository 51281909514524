<template>
  <v-footer dark
            height="320"
            width="auto"
            class="py-12"
            style="background-color: transparent!important">
    <v-container fluid>
      <v-row justify="center">
        <v-col class="pa-0" cols="6" align="center">
          <p class="pa-0 ma-0 footer-powered" :style="{color: $vuetify.theme.dark ? '#ffffff' : '#8898aa'}">Powered by:</p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="pa-0 ma-0" align="center">
          <a href="https://keybe.ai/">
            <v-img
              src="https://storage.googleapis.com/keybe/kb.live/img/footer-logo.png"
              width="70px"
            >
            </v-img>
          </a>
        </v-col>
      </v-row>
      <FooterIcons />
    </v-container>
  </v-footer>
</template>
<script>
import FooterIcons from './FooterIcons'
export default {
  name: 'Footer',
  components: { FooterIcons }
}
</script>
<style scoped>
.footer-powered {
  font-size: 14px;
}
</style>
