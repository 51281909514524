<template>
  <div class="kb-live-background" :style="layoutStyle">
    <div class="main-layout-container">
      <Header />
      <!-- if hash route starts with #kb-profile/ -->
      <div v-show="isKeybeProfile"
        style="padding-top: 100px!important;">
        <div id="keybe" />
      </div>
      <v-main>
          <template v-if="eventsJsLoaded">
            <router-view
              @show-prev-venue="$emit('show-prev-venue')"
              @show-next-venue="$emit('show-next-venue')"
              @select-venue="($evt) => $emit('select-venue', $evt)"
              @check-is-mobile-redirect-to-venue="$emit('check-is-mobile-redirect-to-venue')"
              :venuesData="venuesData"
              v-show="!isKeybeProfile"
            />
          </template>
          <template v-else>
            <v-row align="center" class="pt-10" justify="center">
              <v-progress-circular
                :size="70"
                :width="7"
                color="amber"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </template>
      </v-main>
      <Footer v-if="$route.name !== 'Home' || $vuetify.breakpoint.width > 768"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/Header/Index.vue'
import Footer from '@/components/Footer/Index.vue'
import windowSize from '../mixins/windowSize'
export default {
  name: 'MainLayout',
  mixins: [windowSize],
  computed: {
    ...mapState({
      eventsJsLoaded: state => state.events.eventsJsLoaded
    }),
    layoutStyle () {
      let backgroundImage = {
        'background-image': this.$vuetify.theme.dark ? 'url("https://storage.googleapis.com/keybe-live/img/back-kb1.png")' : 'url(https://storage.googleapis.com/keybe-live/img/back-kb2.png)',
        'background-repeat': 'repeat'
      }
      return backgroundImage
    }
  },
  data () {
    return {
      isKeybeProfile: false
    }
  },
  components: {
    Header,
    Footer
  },
  mounted () {
    this.locationHashChanged()
  },
  methods: {
    locationHashChanged () {
      console.log('hashchange')
      let hash = window.location.hash
      // Remove the leading # characer
      hash = hash.substring(1)
      let hashParts = hash.split('/')
      this.isKeybeProfile = hashParts.length >= 2 && hashParts[1] === 'kb-profile'

      if (!this.isKeybeProfile) {
        window.location.hash = ''
        window.keybe.uiCloseModal()
      }
    }
  },
  props: {
    venuesData: {
      type: Array,
      required: true
    }
  },
  watch: {
    $route (newValue, oldValue) {
      if (newValue.hash !== oldValue.hash) {
        this.locationHashChanged()
      }
    }
  }
}
</script>
<style>
.v-main {
  /* max-width: 98% !important; */
  max-height: 98% !important;
}
</style>
<style lang="scss" scoped>
.main-layout-container {
  // background-color: var(--v-kbGray-base);
  width: 100%!important;
  // padding-right: 20px!important;
  // padding-left: 20px!important;
}
@media only screen and (min-width: 768px) {
  .main-layout-container {
    width: 768px!important;
    margin: 0 auto!important;
  }
}
.kb-live-background {
  width: 100%!important;
  height: 100%!important;
}
</style>
