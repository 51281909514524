import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import { i18n } from '@/plugins/i18n.js'
import App from '@/App.vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import { VueHammer } from 'vue2-hammer'
import VueSocialSharing from 'vue-social-sharing'
import Snotify from 'vue-snotify'
import Siema from 'vue2-siema'
import VueTelInput from 'vue-tel-input'

import router from '@/router'
import store from '@/store'

import vuetify from '@/plugins/vuetify'

import './assets/css/main.scss'

Vue.use(Vue2Filters)
Vue.use(VueMoment, {
  moment
})
Vue.use(VueHammer)
// Lateral swipe was blocking vertical scrolling:
// https://github.com/vuejs/vue-touch/issues/81#issuecomment-300405757
VueHammer.config.swipe = {
  direction: 'horizontal'
}
Vue.use(VueSocialSharing)
Vue.use(Snotify)
Vue.use(Siema)
Vue.use(VueTelInput)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#keybe-live-front')
