<template>
  <v-app-bar
    :height="69"
    app
    dark
    :color="$vuetify.theme.dark ? '#000000' : '#8898AA'"
    class="pa-0 kb-header"
  >
    <v-container d-flex class="header-container align-center pl-5 pr-2 py-3 mx-0">
      <div class="d-flex align-start">
        <v-img
          style="cursor: pointer" @click="goToHome"
          maxHeight="29px"
          maxWidth="130px"
          src="https://storage.googleapis.com/keybe/kb.live/img/header-logo.png?update=true"
        />
      </div>
      <v-spacer></v-spacer>
      <div style="display: flex; align-items: center;">
        <v-btn icon small @click="darkMode()">
          <v-icon v-if="$vuetify.theme.dark">mdi-weather-sunny</v-icon>
          <v-icon dark v-else>mdi-weather-night</v-icon>
        </v-btn>
        <HeaderSidebarMenu/>
      </div>
    </v-container>
  </v-app-bar>
</template>
<script>
import windowSize from '../../mixins/windowSize'
import HeaderSidebarMenu from './HeaderSidebarMenu'
import backgroundImage from '@/mixins/backgroundImage'

import { mapState } from 'vuex'
export default {
  mixins: [windowSize, backgroundImage],
  components: {
    // HeaderProfile,
    HeaderSidebarMenu
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.authKeybe.isLoggedIn
    }),
    isDarkTheme: {
      get () {
        return this.$store.state.storedStates.isDarkTheme
      },
      set (value) {
        this.$store.commit('storedStates/SET_IS_DARK_THEME', value)
      }
    }
  },
  mounted () {
    this.$vuetify.theme.dark = this.isDarkTheme
  },
  methods: {
    darkMode () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.isDarkTheme = this.$vuetify.theme.dark
    },
    goToHome () {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style>
.v-toolbar__content {
  padding: 0 !important;
}
.header-container {
  max-width: 100vw !important;
}
.kb-header {
  width: 100%!important;
}
@media only screen and (min-width: 768px) {
  .kb-header {
    width: 768px!important;
    margin: 0 auto!important;
  }
}
</style>
