import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const Home = () =>
  import(/* webpackChunkName: "Home" */ '@/pages/Home/Index.vue')

const Venues = () =>
  import(/* webpackChunkName: "Home" */ '@/pages/Venues/Index.vue')

const VenueEventDetail = () =>
  import(
    /* webpackChunkName: "VenueEventDetail" */ '@/pages/Venues/VenueEvent/VenueEventDetail.vue'
  )

const VenueEventLiveStream = () =>
  import(
    /* webpackChunkName: "VenueEventLiveStream" */ '@/pages/Venues/VenueEvent/VenueEventLiveStream.vue'
  )

const VenueEventPurchase = () =>
  import(
    /* webpackChunkName: "VenueEventPurchase" */ '@/pages/Venues/VenueEvent/VenueEventPurchase.vue'
  )

const VenuePlaceDetail = () =>
  import(
    /* webpackChunkName: "VenuePlaceDetail" */ '@/pages/Venues/VenuePlace/VenuePlaceDetail.vue'
  )

const VenuePlaceProductToppingsSelector = () =>
  import(
    /* webpackChunkName: "VenuePlaceProductToppingsSelector" */ '@/pages/Venues/VenuePlace/VenuePlaceProductToppingsSelector.vue'
  )

const VenuePlacePurchase = () =>
  import(
    /* webpackChunkName: "VenuePlacePurchase" */ '@/pages/Venues/VenuePlace/VenuePlacePurchase.vue'
  )

const Purchases = () =>
  import(/* webpackChunkName: "Purchases" */ '@/pages/Purchases/Index.vue')

const PurchaseDetail = () =>
  import(
    /* webpackChunkName: "PurchaseDetail" */ '@/pages/Purchases/PurchaseDetail/Index.vue'
  )

const Filters = () =>
  import(
    /* webpackChunkName: "Filters" */ '@/pages/Filters/Index.vue'
  )

const Planner = () =>
  import(
    /* webpackChunkName: "Planner" */ '@/pages/Planner/Planner.vue'
  )

const routes = [
  { path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    component: Venues,
    children: [
      {
        path: 'event/:id/detail',
        name: 'VenueEventDetail',
        component: VenueEventDetail
      },
      {
        path: 'event/:id/stream',
        name: 'VenueEventLiveStream',
        component: VenueEventLiveStream
      },
      {
        path: 'place/:id/detail',
        name: 'VenuePlaceDetail',
        component: VenuePlaceDetail
      },
      {
        path: 'place/:id/product/:productId',
        name: 'VenuePlaceProductToppingsSelector',
        component: VenuePlaceProductToppingsSelector
      }
    ]
  },
  {
    path: '/event/:id/purchase',
    name: 'VenueEventPurchase',
    component: VenueEventPurchase
  },
  {
    path: '/place/:id/purchase',
    name: 'VenuePlacePurchase',
    component: VenuePlacePurchase
  },
  {
    path: '/planner',
    name: 'Planner',
    component: Planner
  },
  {
    path: '/purchases',
    name: 'Purchases',
    component: Purchases
  },
  {
    path: '/purchases/:id/detail',
    name: 'PurchaseDetail',
    component: PurchaseDetail
  },
  {
    path: '/filters',
    name: 'Filters',
    component: Filters
  }
]

const router = new Router({
  mode: 'history',
  scrollBehavior () {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 100)
    })
  },
  routes
})

export default router
