import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const opts = {
  breakpoint: {
    mobileBreakpoint: 'sm' // This is equivalent to a value of 960
  },
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        primary: '#fff',
        gold: '#DBCC47',
        background: '#111111',
        kbSemiBlack: '#222222',
        kbGreen: '#2DCE98',
        kbBlue: '#0BD6DC',
        kbGray: '#222',
        kbRed: '#FA0505',
        kbLittleGray: '#353535'
      },
      light: {
        primary: '#6e6f90',
        gold: '#DBCC47',
        background: '#FFFFFf',
        kbSemiBlack: '#eef1f5',
        kbGreen: '#2DCE98',
        kbBlue: '#0BD6DC',
        kbGray: '#F6F9FC',
        kbRed: '#FA0505',
        kbLittleGray: '#353535'
      }
    }
  }
}

export default new Vuetify(opts)
