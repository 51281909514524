// import Vue from "vue";
import router from '@/router'

const state = {
  places: [],
  selectedPlace: {},
  selectedPlaceCategoriesProducts: [],
  selectedProductCategoryId: undefined,
  selectedProductCategoryName: undefined,
  selectedPlaceProducts: []
}

const mutations = {
  SET_PLACES (state, places) {
    state.places = places
  },
  SET_SELECTED_PLACE (state, place) {
    state.selectedPlace = place
  },
  SET_SELECTED_PLACE_CATEGORIES_PRODUCTS (state, selectedPlaceCategoriesProducts) {
    state.selectedPlaceCategoriesProducts = selectedPlaceCategoriesProducts
  },
  SET_SELECTED_PLACE_PRODUCTS_CATEGORY_ID (state, selectedProductCategoryId) {
    state.selectedProductCategoryId = selectedProductCategoryId
  },
  SET_SELECTED_PLACE_PRODUCTS_CATEGORY_NAME (state, selectedProductCategoryName) {
    state.selectedProductCategoryName = selectedProductCategoryName
  },
  SET_SELECTED_PLACE_PRODUCTS (state, selectedPlaceProducts) {
    state.selectedPlaceProducts = selectedPlaceProducts
  }
}

const actions = {
  async getPlaces ({ commit, rootState }) {
    if (rootState.filters.enabledFilters.places || rootState.filters.enabledFilters.restaurants) {
      const response = await window.keybeEvents.getPlaces(rootState.filters.formattedPlacesFilter)
      if (Array.isArray(response)) {
        const activePlaces = []
        for (let i = 0; i < response.length; i++) {
          if (response[i].active === true) activePlaces.push(response[i])
        }
        return commit('SET_PLACES', activePlaces)
      }
    }
    commit('SET_PLACES', [])
  },
  async selectPlace  ({ commit }, venuePlace) {
    commit('storedStates/SET_SELECTED_PLACE_ID', venuePlace.id, { root: true })
    router.push({
      name: 'VenuePlaceDetail',
      params: { id: venuePlace.id }
    })
  },
  async getPlace ({ commit }, id) {
    const place = await window.keybeEvents.getPlace(id)
    if (place && place.id) {
      commit('SET_SELECTED_PLACE', place)
    } else {
      commit('SET_SELECTED_PLACE', {})
    }
  },
  async getSelectedPlaceCategoriesProducts ({ rootState, commit }) {
    if (rootState.storedStates.selectedPlaceId) {
      const { rows: selectedPlaceCategoriesProducts } = await window.keybeEvents.getCategoriesProductsList({ placeId: rootState.storedStates.selectedPlaceId }) || {}
      if (Array.isArray(selectedPlaceCategoriesProducts)) {
        return commit('SET_SELECTED_PLACE_CATEGORIES_PRODUCTS', selectedPlaceCategoriesProducts)
      }
    }
    return commit('SET_SELECTED_PLACE_CATEGORIES_PRODUCTS', [])
  },
  async getSelectedPlaceProducts ({ rootState, commit }) {
    if (rootState.storedStates.selectedPlaceId) {
      const { rows: selectedPlaceProducts } = await window.keybeEvents.getProductsList({
        placeId: rootState.storedStates.selectedPlaceId,
        productsCategoryId: rootState.places.selectedProductCategoryId
      }) || {}
      if (Array.isArray(selectedPlaceProducts)) {
        return commit('SET_SELECTED_PLACE_PRODUCTS', selectedPlaceProducts)
      }
    }
    return commit('SET_SELECTED_PLACE_PRODUCTS', [])
  },
  async showPlace ({ state, rootState, commit }, placeIndex) {
    commit('SET_SELECTED_PLACE', state.places[placeIndex])
    commit('storedStates/SET_SELECTED_PLACE_ID', state.places[placeIndex].id, { root: true })
    router.push({
      name: 'VenuePlaceDetail',
      params: { id: state.places[placeIndex].id }
    })
  }
}

const getters = {
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
