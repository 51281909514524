<template>
  <v-menu left bottom>
    <template v-slot:activator="{ on }">
      <v-btn :color="$vuetify.theme.dark ? '#FFFFFF' : '#8898aa'"
             outlined
             rounded
             v-on="on">
        <v-icon class="mr-1 hidden-sm-and-down">mdi-earth</v-icon>
        <span :style="{color: $vuetify.theme.dark ? '#FFFFFF' : '#8898aa'}">{{language}}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(language, index) in languages"
                   :key="index"
                   @click="changeLanguage(language.code)">
        <v-list-item-title>
          <span class="kbDarkBlue--text">{{ language.name }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { mapState } from 'vuex'
export default {
  created () {
    // let lang = navigator.language.substr(0, 2)
    let language = this.language
    this.$i18n.locale = language
    this.$moment.locale(language)
  },
  mounted () {
  },
  computed: {
    ...mapState({
      language: state => state.storedStates.selectedLanguage
    })
  },
  data () {
    return {
      languages: [
        { name: 'Español', code: 'es', flag: 'es' },
        { name: 'English', code: 'en', flag: 'us' }
        // { name: 'Português', code: 'pt', flag: 'pt' },
        // { name: '中文', code: 'cn', flag: 'cn' },
        // { name: 'Deutsch', code: 'de', flag: 'de' },
        // { name: 'हिन्दी', code: 'in', flag: 'in' }
      ]
    }
  },
  methods: {
    changeLanguage (code) {
      this.$store.commit('storedStates/SET_SELECTED_LANGUAGE', code)
      this.$i18n.locale = code
      this.$moment.locale(code)
      window.keybe.uiSetLanguage(code)
    }
  },
  props: {
    icon: {
      type: Boolean
    },
    outlined: {
      type: Boolean
    },
    size: {
      type: [String, Number]
    }
  }
}
</script>
