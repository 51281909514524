const state = {
  isLoggedIn: null,
  userInfo: null,
  extraData: {},
  appInfo: null
}

const mutations = {
  CLEAR_USER_INFO (state) {
    state.userInfo = null
  },
  SET_IS_LOGGED_IN (state, value) {
    state.isLoggedIn = value
  },
  SET_USER_INFO (state, userInfo) {
    state.userInfo = userInfo
  },
  SET_EXTRA_DATA (state, userInfo) {
    if (!userInfo.extraData) return
    userInfo.extraData.forEach(element => {
      state.extraData = Object.assign({}, state.extraData, { [element.field]: element.value })
    })
  },
  SET_APP_INFO (state, value) {
    state.appInfo = value
  }
}

const actions = {
  async getAppInfo (vuexContext) {
    try {
      const appInfo = await window.keybe.getAppInfo()
      vuexContext.commit('SET_APP_INFO', appInfo)
      return true
    } catch (e) {
      console.error(e)
      vuexContext.commit('SET_APP_INFO', null)
    }
  },
  async checkUserExist (vuexContext, {
    emailOrPhone, countryCode, validationMethod
  }) {
    try {
      const userExist = window.keybe.userExist(emailOrPhone, undefined, countryCode, validationMethod)
      return userExist
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async sendSmsCodeToLogin (vuexContext, { email, phone, countryCode }) {
    try {
      const response = await window.keybe.loginSmsCodeSend(email, phone, undefined, countryCode)
      if (response && response.phoneLastDigits) {
        // vuexContext.commit('SET_PHONE_LAST_DIGITS', response.phoneLastDigits)
        return response.phoneLastDigits
      }
    } catch (e) {
      vuexContext.commit('SET_IS_LOGGED_IN', false)
      vuexContext.commit('CLEAR_USER_INFO')
      throw (e)
    }
  },
  async sendSmsCodeToRegister (vuexContext, { email, phone, countryCode }) {
    try {
      const response = await window.keybe.registerSmsCodeSend({ phone, countryCode, email })
      if (response && response.phoneLastDigits) {
        // vuexContext.commit('SET_PHONE_LAST_DIGITS', response.phoneLastDigits)
        return response.phoneLastDigits
      }
      return response
    } catch (e) {
      vuexContext.commit('SET_IS_LOGGED_IN', false)
      vuexContext.commit('CLEAR_USER_INFO')
      throw (e)
    }
  },
  async validateSmsCodeToLogin (vuexContext, { email, phone, countryCode, name, code, password }) {
    try {
      // let { email, phone, country } = vuexContext.state.login
      // let { name } = vuexContext.state.register
      const user = await window.keybe.loginSmsCodeValidate(email, phone, undefined, countryCode, code, password, name)
      return user
    } catch (e) {
      vuexContext.commit('SET_IS_LOGGED_IN', false)
      vuexContext.commit('SET_USER', null)
      throw (e)
    }
  },
  async isLoggedIn ({ commit }) {
    const isLoggedIn = await window.keybe.isLoggedIn()
    commit('SET_IS_LOGGED_IN', isLoggedIn)
    if (isLoggedIn) {
      try {
        const userInfo = await window.keybe.getUserInfo()
        // if (Object.keys(userInfo).length > 0) {
        //   commit('SET_IS_LOGGED_IN', true)
        // }
        commit('SET_USER_INFO', userInfo)
        commit('SET_EXTRA_DATA', userInfo)
      } catch (e) {
        console.error(e)
        commit('CLEAR_USER_INFO')
      }
    } else {
      commit('CLEAR_USER_INFO')
    }
    return isLoggedIn
  },
  async updateUser ({ commit, dispatch }, data) {
    const responseData = await window.keybe.updateUserInfo(data)
    return responseData
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
