const state = {
  creditCards: [],
  selectedCreditCard: {},
  purchaseConfirmation: {
    // shippingAddress
    // phoneNumber
    // dues
  },
  pseBanks: [],
  docTypes: [],
  wompiAcceptanceToken: '',
  acceptancePermalink: ''
}

const mutations = {
  SET_USER_CREDIT_CARDS (state, creditCards) {
    state.creditCards = creditCards
  },
  SET_SELECTED_CREDIT_CARD (state, selectedCreditCard) {
    state.selectedCreditCard = selectedCreditCard
  },
  SET_PURCHASE_CONFIRMATION (state, purchaseConfirmation) {
    state.purchaseConfirmation = purchaseConfirmation
  },
  SET_PSE_BANKS (state, banks) {
    if (banks && Array.isArray(banks)) {
      state.pseBanks = banks
    } else {
      state.pseBanks = []
    }
  },
  SET_WOMPI_ACCEPTANCE_TOKEN (state, token) {
    state.wompiAcceptanceToken = token
  },
  SET_ACCEPTANCE_PERMALINK (state, permalink) {
    state.acceptancePermalink = permalink
  },
  SET_DOC_TYPES (state, types) {
    state.docTypes = types
  }
}

const actions = {
  async getUserCards ({ commit }) {
    try {
      const response = await window.keybe.getUserCards()
      commit('SET_USER_CREDIT_CARDS', response)
      return true
    } catch (error) {
      commit('SET_USER_CREDIT_CARDS', [])
      throw error
    }
  },
  async getAcceptanceToken ({ commit }) {
    try {
      const response = await window.keybeEvents.getWompiAcceptanceToken()
      if (response.acceptanceToken) commit('SET_WOMPI_ACCEPTANCE_TOKEN', response.acceptanceToken)
      if (response.permalink) commit('SET_ACCEPTANCE_PERMALINK', response.permalink)
      return true
    } catch (error) {
      commit('SET_WOMPI_ACCEPTANCE_TOKEN', '')
      commit('SET_ACCEPTANCE_PERMALINK', '')
      throw error
    }
  },
  async addCreditCard ({ commit, dispatch }, data) {
    const {
      // cardToken
      cardNumber,
      cardExpirationMonth,
      cardExpirationYear,
      securityCode,
      cardholderName,
      cardholderLastName,
      email,
      docNumber,
      docType,
      acceptanceToken
    } = data
    await window.keybe.tokenizeCard({
      // undefined, // cardToken
      cardNumber,
      expYear: cardExpirationYear,
      expMonth: cardExpirationMonth,
      cvc: securityCode,
      name: cardholderName,
      lastName: cardholderLastName,
      docType,
      docNumber,
      instantPay: true, // instantPay
      defaultDues: 1,
      email,
      acceptanceToken
    })
    // getListCreditCards
    await dispatch('getUserCards')
  },
  async setCardAsMain ({ commit, dispatch }, cardId) {
    await window.keybe.setCardAsMain(cardId)
    // getListCreditCards
    await dispatch('getUserCards')
  },
  async deleteCard ({ commit, dispatch }, cardId) {
    await window.keybe.deleteCard(cardId)
    // getListCreditCards
    await dispatch('getUserCards')
  },
  async getPseBanks ({ commit }) {
    const provider = 'wompi'
    let pseBanks = []
    try {
      const pseBanksResponse = await window.keybeEvents
        .getPSEBanks(provider)
      if (pseBanksResponse && Array.isArray(pseBanksResponse)) {
        pseBanks = pseBanksResponse
      }
    } catch (error) {
      console.error(error)
    }
    commit('SET_PSE_BANKS', pseBanks)
  },
  async getIdentificationTypes ({ commit }) {
    const provider = 'wompi'
    let docTypes = []
    try {
      const docTypesResponse = await window.keybeEvents
        .getIdentificationTypes(provider)
      if (docTypesResponse && Array.isArray(docTypesResponse)) {
        docTypes = docTypesResponse
      }
    } catch (error) {
      console.error(error)
    }
    commit('SET_DOC_TYPES', docTypes)
  }
}

const getters = {
  mainCreditCard (state) {
    const mainCreditCard = state.creditCards.filter((el) => el.priority === 0)
    return mainCreditCard.length > 0 ? mainCreditCard[0] : {}
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
