<template>
  <div class="main-header__profile" @click="$emit('click')">
    <v-row class="profile-container align-center">
      <v-col class="profile-container_avatar flex-shrink-0 flex-grow-0">
        <v-avatar :color="$vuetify.theme.dark ? 'white' : 'gray'" style="cursor: pointer" max-width="50px" max-height="50px">
          <img :src="getImage" v-if="userInfo && userInfo.image" />
          <img
            v-else
            :src="getImage"
            style="width: 27px; height: 30px"
          />
        </v-avatar>
      </v-col>
      <v-col
        class="profile-container_account py-0 flex-grow-1 flex-shrink-0"
      >
        <a class="profile-user text-center" :style="textColor">
          {{ userInfo ? userInfo.name : $t("yourAccount") }}
        </a>
        <a class="profile-user" :style="textColor">
          {{ userInfo ? userInfo.UUID : 'KB:' }}
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import windowSize from '../../mixins/windowSize'
export default {
  name: 'HeaderProfile',
  mixins: [windowSize],
  computed: {
    ...mapState({
      userInfo: (state) => state.authKeybe.userInfo
    }),
    textColor () {
      return { color: this.$vuetify.theme.dark ? '#FFFFFF' : '#8898aa' }
    },
    getImage () {
      if (this.userInfo && this.userInfo.image) return this.userInfo.image
      return 'https://storage.googleapis.com/keybeeventsui/images/usuario.svg'
    }
  },
  methods: {
  }
}
</script>

<style>
.profile-container_avatar img {
  object-fit: cover;
}
.profile-user {
  font-size: 14px;
  font-weight: bold;
}
</style>

<style scoped>
@media only screen and (max-width: 600px) {
  .profile-user {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}
</style>
<style lang="scss" scoped>
.main-header__profile {
  .profile-container_account{
    max-height: 100%;
    overflow: hidden;
    align-items: center;
    max-width: 100%;
    a {
      width: 100%;
      min-width: 100%;
      display: block;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
