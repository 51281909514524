import Vue from 'vue'
const state = {
  // Products
  productsCategories: [],
  productCartItems: [],
  selectedPlaceProductId: '',
  selectedPlaceProduct: {}
}
const mutations = {
  // Products
  SET_PRODUCTS_CATEGORIES (state, categories) {
    state.productsCategories = categories
  },
  ADD_PRODUCT_TO_CART (state, product) {
    state.productCartItems.push(product)
  },
  REMOVE_PRODUCT_FROM_CART (state, index) {
    state.productCartItems.splice(index, 1)
  },
  SET_PRODUCT_CART_ITEM (state, { index, product }) {
    if (state.productCartItems[index]) {
      Vue.set(state.productCartItems, index, product)
    }
  },
  CLEAR_CART_ITEMS (state) {
    Vue.set(state, 'productCartItems', [])
  },
  SET_SELECTED_PLACE_PRODUCT_ID (state, selectedPlaceProductId) {
    state.selectedPlaceProductId = selectedPlaceProductId
  },
  SET_SELECTED_PLACE_PRODUCT (state, product) {
    Vue.set(state, 'selectedPlaceProduct', product)
  }
}
const actions = {
  // Products
  async getProductsCategories ({ commit }) {
    const { rows: selectedPlaceProductsCategories } = await window.keybeEvents.getCategoriesProductsList()
    if (Array.isArray(selectedPlaceProductsCategories)) {
      return commit('SET_PRODUCTS_CATEGORIES', selectedPlaceProductsCategories)
    }
    return commit('SET_PRODUCTS_CATEGORIES', [])
  },
  async getProductsList ({ dispatch, commit }) {
    try {
      let products = await window.keybeEvents.getProductsList()
      await commit('SET_PRODUCTS_LIST', products.rows)
    } catch (error) {
      console.error(error)
      await commit('SET_PRODUCTS_LIST', [])
    }
  },
  async getProductById ({ state, dispatch, commit }) {
    try {
      let product = await window.keybeEvents.getProduct({ productId: state.selectedPlaceProductId })
      await commit('SET_SELECTED_PLACE_PRODUCT', product)
    } catch (error) {
      console.error(error)
      await commit('SET_SELECTED_PLACE_PRODUCT', [])
    }
  },
  async setProductPurchase ({ state }) {
    try {
      const data = {
      }
      // data.shippingAddress = state.purchaseConfirmation.shippingAddress
      // data.phoneNumber = state.purchaseConfirmation.phoneNumber

      data.cartItems = state.productCartItems
      // console.log('data')
      // console.log(data)

      const productPurchaseResponse = await window.keybeEvents.setProductsPurchase(data)
      // return response
      // console.log('productPurchaseResponse')
      // console.log(productPurchaseResponse)
      return productPurchaseResponse
    } catch (e) {
      console.error(e)
      throw e
    }
  },
  async setProductPurchasePayment ({ rootState }, productPurchaseResponse) {
    try {
      let chargeResponse
      if (rootState.money.purchaseConfirmation.paymentMethod === 'creditCard') {
        chargeResponse = await window.keybeEvents.chargeCard({
          purchaseId: productPurchaseResponse.purchase._id,
          cardId: rootState.money.purchaseConfirmation.cardId,
          dues: rootState.money.purchaseConfirmation.dues,
          purchaseType: 'product'
        },
        rootState.money.selectedCreditCard.provider)
      } else if (rootState.money.purchaseConfirmation.paymentMethod === 'pse') {
        chargeResponse = await window.keybeEvents.createPSETransaction({
          ignoreProvider: true,
          purchaseId: productPurchaseResponse.purchase._id,
          email: rootState.money.purchaseConfirmation.email,
          docType: rootState.money.purchaseConfirmation.docType,
          docNumber: rootState.money.purchaseConfirmation.docNumber,
          typePerson: rootState.money.purchaseConfirmation.typePerson,
          bank: rootState.money.purchaseConfirmation.bank,
          urlResponse: rootState.money.purchaseConfirmation.urlResponse,
          purchaseType: 'product',
          acceptanceToken: rootState.money.purchaseConfirmation.acceptanceToken
        })
      } else if (rootState.money.purchaseConfirmation.paymentMethod === 'cash') {
        console.log('not imp')
      } else if (rootState.money.purchaseConfirmation.paymentMethod === 'mercadopago') {
        console.log('not imp')
      }
      // console.log(chargeResponse)
      return chargeResponse
    } catch (e) {
      console.error(e)
      throw e
    }
  }
}

const getters = {
  totalPriceCartProducts (state) {
    let totalPriceCartProducts = 0
    for (let i = 0; i < state.productCartItems.length; i++) {
      const productCartItem = state.productCartItems[i]
      if (productCartItem.numberProducts && productCartItem.price) {
        totalPriceCartProducts += (productCartItem.numberProducts * productCartItem.price)
      }
    }
    return totalPriceCartProducts
  },
  countCartProducts (state) {
    return (state.productCartItems &&
      Array.isArray(state.productCartItems) && state.productCartItems.length) ||
      0
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
